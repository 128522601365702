import { ReactElement } from 'react'

import Button from '@mui/material/Button'
import getCSSValue from 'components/utils'
import { SxProps } from '@mui/material'

import './SimpleButton.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
}

type Props = {
  title: string;
  onClick: () => void;
  style?: ButtonStyle;
  customStyle?: SxProps;
  disabled?: boolean;
} & typeof defaultProps

const defaultProps = {
  style: ButtonStyle.primary,
  customStyle: {} as SxProps,
  disabled: false,
}

const primaryStyle: SxProps = {
  borderRadius: '23px',
  backgroundColor: getCSSValue('--color-blue-500'),
  color: getCSSValue('--color-near-white'),
  fontSize: 16,
  paddingTop: '10px',
  maxWidth: '450px',
}

const lightStyle: SxProps = {
  borderRadius: '4px',
  backgroundColor: getCSSValue('--color-white'),
  color: getCSSValue('--color-grey-800'),
  fontSize: 12,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06)',
  ':hover': {
    backgroundColor: getCSSValue('--color-lighter-grey'),
    color: getCSSValue('--color-grey-800'),
  },
}

export default function SimpleButton({
  title, onClick, style, customStyle, disabled,
}: Props): ReactElement {
  const getCustomStyle = () => {
    if (customStyle !== null && Object.keys(customStyle).length !== 0) {
      return customStyle
    }
    if (style) {
      switch (style) {
        case ButtonStyle.light:
          return lightStyle
        case ButtonStyle.primary:
          return primaryStyle
        default:
          break
      }
    }
    return primaryStyle
  }

  const computedStyle: SxProps = {
    textTransform: 'none',
    width: '100%',
    fontFamily: 'Avenir',
    ...getCustomStyle(),
  }

  return (
    <div className="simple-button">
      <Button
        variant="contained"
        size="large"
        onClick={onClick}
        sx={computedStyle}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  )
}

SimpleButton.defaultProps = defaultProps
